import React, { useState } from "react";
import { BiChevronDown, BiUserCircle, BiSearch, BiLogOut, BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import './style.css';
import axios from "axios";
import API from "../../apis/api";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";

function NavLg() {
  const [profileData, setProfileData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Toggle menu for mobile
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    API.getUserDetails()
      .then(({ data }) => {
        setProfileData(data.userInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logoutFunc = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
    dispatch(logout());
    navigate('/login');
  };

  return (
    <>
      <div className="container flex mx-auto px-2 items-center justify-between">
        {/* Logo and Title */}
        <div className="flex items-center w-1/2 gap-3">
          <div className="w-30 h-14" style={{ cursor: "pointer" }} onClick={() => navigate('/')}>
            <img
              src={require('../../assets/logo.jpeg')}
              alt="logo"
              style={{
                height: 50,
                width: 60
              }}
              className="w-full h-full"
            />
          </div>
          <div className="w-full flex items-center">
            <p className="text-white text-2xl">Book Our Seat</p>
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center gap-5">
         
          {!profileData ? (
            <button className="bg-white text-darkBackground-700 px-2 py-1 text-sm rounded">
              Sign In
            </button>
          ) : (
            <div className="flex items-center gap-3">
              <Link to="/" className="text-white text-lg">Home</Link>
              <Link to="/add_credits" className="text-white text-lg">Add Credits</Link>
              <Link to="/bookings" className="text-white text-lg">Bookings</Link>
              <Link to="/about_us" className="text-white text-lg">About Us</Link>
              <Link to="/profile" className="text-white text-lg">Profile</Link>
              {/* <p className="text-white text-lg">{profileData.name}</p>
              <div className="w-8 h-8 text-white cursor-pointer" onClick={() => navigate('/profile')}>
                <BiUserCircle className="w-full h-full" />
              </div> */}
              <div className="w-8 h-8 text-white cursor-pointer" onClick={logoutFunc}>
                <BiLogOut className="w-full h-full" />
              </div>
            </div>
          )}
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <BiMenu className="text-white w-8 h-8 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </div>
      </div>

      {/* Mobile Menu Items */}
      {isMenuOpen && (
        <div className="md:hidden bg-darkBackground-700 p-4 flex flex-col gap-3">
          <Link to="/" className="text-white text-lg" onClick={() => setIsMenuOpen(false)}>Home</Link>
          <Link to="/add_credits" className="text-white text-lg" onClick={() => setIsMenuOpen(false)}>Add Credits</Link>
          <Link to="/bookings" className="text-white text-lg" onClick={() => setIsMenuOpen(false)}>Bookings</Link>
          <Link to="/about_us" className="text-white text-lg" onClick={() => setIsMenuOpen(false)}>About Us</Link>
          <Link to="/profile" className="text-white text-lg" onClick={() => setIsMenuOpen(false)}>Profile</Link>
          {!profileData ? (
            <button className="bg-white text-darkBackground-700 px-2 py-1 text-sm rounded">
              Sign In
            </button>
          ) : (
            <div className="flex flex-col items-start gap-3">
              {/* <div className="flex items-center gap-3">
                <BiUserCircle className="text-white w-6 h-6" />
                <p className="text-white text-lg">{profileData.name}</p>
              </div> */}
              {/* <div className="flex items-center gap-3" onClick={() => setIsMenuOpen(false)}>
                <Link to="/profile" className="text-white text-lg">Profile</Link>
              </div> */}
              <div className="flex items-center gap-3 cursor-pointer" onClick={logoutFunc}>
                <BiLogOut className="text-white w-6 h-6" />
                <p className="text-white text-lg">Logout</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

// Main Component
const Navbar = () => {
  const user = useSelector((state) => state.auth.user);
  const accessToken = useSelector((state) => state.auth.accessToken);
  console.log(accessToken, 'navbar12211', user);

  return (
    <>
      <nav className="bg-darkBackground-700 px-4 py-3">
        <NavLg />
      </nav>
    </>
  );
};

export default Navbar;
