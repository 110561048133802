import React, { useEffect, useState, useContext } from "react";
import MovieLayoutHoc from "../layout/Movie.layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MovieContext } from "../context/Movie.context";
import Slider from "react-slick";
import { FaCcVisa, FaCcApplePay } from "react-icons/fa";
import PosterSlider from "../components/PosterSlider/PosterSlider.Component";
import MovieHero from "../components/MovieHero/MovieHero.Component";
import Cast from "../components/Cast/Cast.Component";
import API from "../apis/api";
import Footer from "../components/Footer";

const MoviePage = ({ token }) => {
  const { id } = useParams();
  const { movie, setMovie } = useContext(MovieContext);
  const [cast, setCast] = useState([]);
  // const [event, setevent] = useState(null)
  let allEvents = JSON.parse(localStorage.getItem('allEvents'));
  let event = JSON.parse(localStorage.getItem('eventDetail'));

  // useEffect(() => {
  //   if (token) {
  //     API.getEventByID({ id })
  //       .then((data) => {
  //         console.log(data);
  //         setevent(data.events)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }, [token]);
  if(!event)return null
  return (
    <>
      <MovieHero event={event} />
      <div className="my-12 container px-4 lg-ml-20 lg:w-2/3">
        <div className="flex flex-col items-start gap-3 p-[35px]">
          <h1 className="text-gray-800 font-bold text-2xl">About the Event</h1>
          <p>{event.description}</p>
        </div>

        <div className="my-8">
          <hr />
        </div>
        <div className="my-8">
          <hr />
        </div>

        {/* Cast Slider */}
        <div className="my-8 p-[35px]">
          <img src={event?.artistImageLink} />
          <h2 className="text-gray-800 font-bold text-2xl mb-4">
            Artist
          </h2>
          <p>{event?.artistName}</p>
        </div>

        <div className="my-8">
          <hr />
        </div>

        {/* recommended movies slider */}
        <div className="">
          <PosterSlider
            title="Upcoming Events"
            subtitle="List of upcoming movies"
            posters={allEvents}
            isDark={false}
          />
        </div>

        <div className="my-8">
          <hr />
        </div>

      </div>
        <Footer />
    </>
  );
};

export default MovieLayoutHoc(MoviePage);