import React, { useState, useEffect } from 'react';
import API from '../apis/api';
import { useToast } from '../components/ToastProvider';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar.Component';

const padWithEmptySeats = (rowsParsed, numOfCols) => {
    return rowsParsed.map(row => {
        let length = row.length;
        let extra = numOfCols - length;
        let paddingFront = Math.floor(extra / 2);
        let paddedRow = [];
        for (let i = 0; i < length + extra; i++) {
            if (i < paddingFront || i >= paddingFront + length) {
                paddedRow.push([0, -1, -1]);
            } else {
                paddedRow.push(row[i - paddingFront]);
            }
        }
        return paddedRow;
    });
};

const parseLayout = (layoutString) => {
    let layoutRowStrings = layoutString.split('|');
    let maxElementsInARow = 0;
    let rowsParsed = [];
    layoutRowStrings.forEach((rowString, rowIdx) => {
        rowsParsed.push(
            rowString.split('').map((char, colIdx) => {
                return char === '_' ? [0, rowIdx, colIdx] : [1, rowIdx, colIdx];
            })
        );
        maxElementsInARow = Math.max(rowsParsed[rowsParsed.length - 1].length, maxElementsInARow);
    });
    let numOfCols = maxElementsInARow;
    let numOfRows = rowsParsed.length;
    rowsParsed = padWithEmptySeats(rowsParsed, numOfCols);
    return {
        rowCount: numOfRows,
        colCount: numOfCols,
        layoutExtracted: rowsParsed,
    };
};

const LayoutRow = ({ rowLayout, rowLabel, getDetails, updateCart }) => {
    const [selectedSeats, setSelectedSeats] = useState([]);

    const getStyles = (idx, isDisabled) => {
        if (isDisabled) {
            return 'w-10 h-10 bg-gray-200 rounded-lg flex items-center justify-center cursor-not-allowed shadow-sm';
        }
        if (!selectedSeats.includes(idx)) return 'w-10 h-10 border border-gray-400 rounded-lg flex items-center justify-center cursor-pointer shadow-md hover:bg-gray-100 transition';
        else return 'w-10 h-10 bg-darkBackground-700 text-white rounded-lg flex items-center justify-center cursor-pointer shadow-md';
    };

    const handleOnPress = (idx, r, c) => {
        let newSeats = selectedSeats;
        if (selectedSeats.includes(idx)) {
            newSeats = selectedSeats.filter(x => x !== idx);
            updateCart(r, c, false);
        } else {
            newSeats.push(idx);
            updateCart(r, c, true);
        }
        setSelectedSeats([...newSeats]);
    };

    const getInfo = (r, c) => {
        let info = getDetails(r, c);
        if (info.length >= 1) {
            return info[0];
        } else return {};
    };

    return (
        <div className="flex flex-row justify-start items-center my-1">
            <div className="w-10 text-center font-semibold text-gray-600">{rowLabel}</div>
            <div className="flex flex-row space-x-2">
                {rowLayout &&
                    rowLayout.map((seat, idx) => {
                        if (seat[0] === 0) {
                            return <div className="w-10 h-10" key={idx}></div>;
                        } else {
                            return (
                                <div
                                    key={idx}
                                    className={getStyles(idx, getInfo(seat[1], seat[2]).status !== 'Available')}
                                    onClick={() => handleOnPress(idx, seat[1], seat[2])}
                                >
                                    <p className="text-xs text-center">{getInfo(seat[1], seat[2]).price}</p>
                                </div>
                            );
                        }
                    })}
            </div>
        </div>
    );
};

const SeatSelection = ({ history, token }) => {
    const toast = useToast();
    const { id } = useParams();
    const eventDetails = JSON.parse(localStorage.getItem('eventDetail'));
    const [showLayoutDetails, setShowLayoutDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState({
        selectedSeats: [],
        seatstoStore: [],
        totalamount: 0,
        cartId: '',
        eventId: eventDetails.id,
        showId: 0,
    });
    const navigate = useNavigate()

    const getDetails = (rowIdx, colIdx) => {
        return (
            showLayoutDetails &&
            showLayoutDetails.showSeats &&
            showLayoutDetails.showSeats.filter(
                showSeat =>
                    showSeat.coordinates[0] === rowIdx + 1 &&
                    showSeat.coordinates[1] === colIdx + 1
            )
        );
    };

    const updateCart = (rowIdx, colIdx, add) => {
        let info = getDetails(rowIdx, colIdx)[0];
        if (add) {
            let selectedSeats = [...cart.selectedSeats];
            selectedSeats.push({ id: info.id, number: info.seatNumber });
            let price = cart.totalamount + info.price;
            setCart({
                ...cart,
                totalamount: price,
                selectedSeats: selectedSeats,
                showId: info.showId,
            });
        } else {
            let selectedSeats = [...cart.selectedSeats.filter(seat => seat.id !== info.id)];
            let price = cart.totalamount - info.price;
            setCart({
                ...cart,
                totalamount: price,
                selectedSeats: selectedSeats,
                showId: info.showId,
            });
        }
    };

    useEffect(() => {
        setLoading(true);
        // window.location.reload()
        API.seatLayout({ showId: eventDetails.shows[0].id })
            .then(({ data }) => {
                setShowLayoutDetails(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                toast.error(err.response?.data.message || err);
                history.goBack();
            });
    }, []);

    const parsedLayout = showLayoutDetails.showVenueLayout
        ? parseLayout(showLayoutDetails.showVenueLayout.layout)
        : null;

    return (
        <div>
            {/* <Navbar /> */}
            <div className="flex flex-col h-screen items-center bg-white">
                <div className="w-full bg-gradient-to-r from-darkBackground-700 to-indigo-500 p-5 shadow-md">
                    <h2 className="text-2xl font-extrabold text-white">{eventDetails.title}</h2>
                    <p className="text-white text-sm mt-2">Date & Time: {eventDetails.shows[0].date} - {eventDetails.shows[0].startTime}</p>
                </div>
                <div className="flex-1 rounded-md w-full overflow-auto">
                    <div className="min-w-full p-5 flex flex-col items-center">
                        {parsedLayout && (
                            <>
                                <div className="flex flex-row items-center justify-center space-x-3 mb-4">
                                    <div className="w-10"></div>
                                    {showLayoutDetails.showVenueLayout.columnLabels.map((label, idx) => (
                                        <div key={idx} className="w-10 h-10 text-center text-gray-600 font-bold">
                                            {label}
                                        </div>
                                    ))}
                                </div>
                                {parsedLayout.layoutExtracted.map((row, rowIdx) => (
                                    <LayoutRow
                                        key={rowIdx}
                                        rowLayout={row}
                                        rowLabel={showLayoutDetails.showVenueLayout.rowLabels[rowIdx]}
                                        getDetails={getDetails}
                                        updateCart={updateCart}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </div>
                <div className="w-3/4 flex items-center my-8 justify-around">
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-8 h-8 border border-gray-300 rounded-md mx-2"></div>
                        <span>Available</span>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-8 h-8 bg-gray-300 rounded-md mx-2"></div>
                        <span>Unavailable</span>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-8 h-8 bg-darkBackground-700 text-white rounded-md mx-2"></div>
                        <span>Selected</span>
                    </div>
                </div>
                <div className="w-full p-5 bg-gray-100 flex items-center justify-between shadow-md">
                    <h3 className="font-bold text-xl text-gray-700">Total Amount: ₹{cart.totalamount}</h3>
                    <button
                        disabled={cart.totalamount === 0}
                        className={`bg-darkBackground-700 text-white text-lg px-6 py-2 rounded-lg hover:bg-primary-600 shadow-lg 
                ${cart.totalamount === 0 ? 'opacity-50 cursor-not-allowed' : ''} 
                backdrop-blur-md transition duration-200`}
                        onClick={() => {
                            if (cart.totalamount > 0) {
                                navigate(`/preview_order?showId=${eventDetails.shows[0].id}&event_Id=${eventDetails?.id}&cart=${encodeURIComponent(JSON.stringify(cart))}`);
                            }
                        }}
                    >
                        Continue
                    </button>

                </div>
            </div>
        </div>
    );
};

export default SeatSelection;
