import React, { useState } from 'react';
import axios from 'axios'; // Axios for API calls
import API from '../apis/api';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { setAuthorizationToken } from '../apis/instance';
import './LoginScreen.css'; // Importing styles from CSS

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      alert('Please fill in both fields');
      return;
    }

    setLoading(true);

    try {
      const response = await API.login({
        email: email,
        password: password,
      });

      if (response.status === 200) {
        alert('Login Successful');
        dispatch(loginSuccess({
          user: response.data,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        }));

        setAuthorizationToken(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
        navigate('/');
      } else {
        alert('Login Failed');
      }
    } catch (error) {
      console.error(error);
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src={require('../assets/loginLogo1.webp')} alt="Login Illustration" className="login-image"/>
        <div className="form-content">
          <h2 className="login-title">Welcome Back!</h2>
          <p className="login-subtitle">Please enter your credentials to login</p>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />

          <button onClick={handleLogin} className="login-button" disabled={loading}>
            {loading ? <div className="spinner"></div> : 'Login'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
