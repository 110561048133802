import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/Navbar.Component'
import IDCard from '../components/IDCard'
import Footer from '../components/Footer'
import API from '../apis/api';

function MembershipCard() {
    const [profileData, setProfileData] = useState(null);
    useEffect(() => {
        API.getUserDetails()
          .then(({ data }) => {
            setProfileData(data.userInfo);
          })
          .catch((err) => {
            console.log(err);
          });
      }, []);
      if(!profileData)return null
  return (
   <div>
    <Navbar /><div className='my-16'>
    <IDCard profileData={profileData} />
    </div>
    <Footer />
   </div>
  )
}

export default MembershipCard