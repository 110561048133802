import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Pages
import HomePage from "./pages/Home.page";
import MoviePage from "./pages/Movie.page";
import PlayPage from "./pages/Play.page";
import EventPage from "./pages/Event.page";
import Profilepage from "./pages/Profilepage";
import SeatSelection from "./pages/Seat.page";
import PreviewOrder from "./pages/PreviewOrder.page";
import OrderSummary from "./pages/OrderSummary.page";
import BookingHistory from "./pages/BookingHistory.page";
import BookingDetail from "./pages/BookingDetail.page";
import LoginScreen from "./pages/LoginScreen";
// APIs
import API from "./apis/api";
import { setAuthorizationToken } from "./apis/instance";
import { loginSuccess } from "./redux/slices/authSlice";
// Protected Route Component
import ProtectedRoute from "./components/ProtectedRoute";
import AboutUs from "./pages/AboutUs";
import AddCredits from "./pages/AddCredits";
import MembershipCard from "./pages/MembershipCard";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    console.log("Retrieved token:", token);
    setAuthorizationToken(token);
    if (token) {
      API.getUserDetails()
        .then(({ data }) => {
          dispatch(
            loginSuccess({
              user: data?.userInfo,
              accessToken: token,
              refreshToken: token,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch]);

  return (
    <Routes>
      {/* Unprotected Routes */}
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events"
        element={
          <ProtectedRoute>
            <EventPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/event/:id"
        element={
          <ProtectedRoute>
            <MoviePage />
          </ProtectedRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profilepage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/membership"
        element={
          <ProtectedRoute>
            <MembershipCard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add_credits"
        element={
          <ProtectedRoute>
            <AddCredits />
          </ProtectedRoute>
        }
      />
      <Route
        path="/select_seat/:id"
        element={
          <ProtectedRoute>
            <SeatSelection />
          </ProtectedRoute>
        }
      />
      <Route
        path="/preview_order"
        element={
          <ProtectedRoute>
            <PreviewOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/about_us"
        element={
          <ProtectedRoute>
            <AboutUs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bookings"
        element={
          <ProtectedRoute>
            <BookingHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/booking-detail/:bookingId"
        element={
          <ProtectedRoute>
            <BookingDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order_summary/:bookingId"
        element={
          <ProtectedRoute>
            <OrderSummary />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
