import React from "react";
import { useNavigate } from "react-router-dom";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: 'short', day: 'numeric', month: 'long' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
}

const EventPoster = (props) => {
  const navigate = useNavigate();
  const eventDate = new Date(props.shows[0].date);
  const isExpired = eventDate < new Date();

  const navigateTOEvent = () => {
    if (!isExpired) {
      localStorage.setItem('eventDetail', JSON.stringify(props));
      navigate(`/event/${props.id}`);
    }
  };
  if(isExpired){
    return null
  }

  return (
    <div
      style={{
        marginLeft: 22,
        cursor: isExpired ? "not-allowed" : "pointer",
        position: "relative",
      }}
      onClick={navigateTOEvent}
    >
      <div
        style={{
          // filter: isExpired ? "blur(4px)" : "none",
          transition: "filter 0.3s ease", // Add transition for smoother effect
        }}
      >
        <img
          src={props?.posterLink}
          alt="poster"
          className="rounded-md object-cover object-center w-full h-full max-w-none"
        />
        <p className="bg-premier-600 w-full bottom-8" style={{ position: "relative", opacity: 0.9, color: isExpired ? 'grey' : '#6200EE', padding: 4, paddingLeft: 7 }}>
          {formatDate(props.shows[0].date)}
        </p>
        <div style={{ bottom: 25, position: "relative" }}>
          <h3 className={`text-lg font-bold ${props.isDark ? "text-white" : isExpired ? "text-grey" : "text-gray-700"}`}>
            {props.title}
          </h3>
          <p style={{ position: "relative", bottom: 10, color: isExpired ? 'grey' : 'grey', marginTop: 5 }}>
            {props.category}
          </p>
          <span style={{ position: "relative", bottom: 15, color: isExpired ? 'grey' : 'grey', width: 200, marginTop: 15 }}>
            {props.shows[0].venue.name + " , " + props.shows[0].venue.city}
          </span>
        </div>
      </div>
      {isExpired && (
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(200, 200, 200, 0.7)", // Light grey overlay
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10,
          color: "#6200EE",
          fontSize: "1.5rem",
          fontWeight: "bold"
        }}>
          Event Expired
        </div>
      )}
    </div>
  );
};

const Poster = (props) => {
  return <EventPoster {...props} />;
};

export default Poster;
