import React from "react";
import { FaMapMarkerAlt, FaClock, FaMusic, FaUsers } from "react-icons/fa"; // Importing icons for styling
import Banner1 from '../../assets/Banner1.jpeg';
import { useNavigate } from "react-router-dom";

const MovieHero = ({ event }) => {
  const { title, description, artistName, duration, ageLimit, posterLink, shows } = event;
  console.log(event, 'MovieHero');
  const navigate = useNavigate();

  // Sum of total bookings from all shows
  const totalBookings = shows.reduce((total, show) => total + show.bookingCount, 0);

  return (
    <div className="bg-[#230056] text-white">
      {/* Full-Width Event Image with Marketing Banner */}
      <div className="relative">
        <img
           src={posterLink}
          alt="Event Poster"
          className="w-full h-[60vh] object-cover shadow-lg"
        />
        {/* Marketing Banner */}
        <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-4 space-y-2">
          <div className="text-center relative bottom-4">
            <FaUsers className="inline-block text-white text-2xl mb-1" />
            <p className="text-lg font-semibold">{totalBookings} people have already booked their tickets!</p>
            <p className="text-sm text-gray-300">Hurry up, book now before tickets are unavailable!</p>
          </div>
          <button className="px-6 py-2 bg-white text-black rounded-lg font-semibold hover:bg-background-700 transition duration-300 bottom-4 relative" onClick={() =>  navigate(`/select_seat/${event?.id}`)}>
            Book Now
          </button>
        </div>
      </div>

      {/* Event Details Section */}
      <div className="px-8 py-6 space-y-6">
        {/* Event Title */}
        <h1 className="text-4xl font-bold mb-2">{title}</h1>
        <p className="text-lg text-gray-300">{description}</p>

        {/* Artist, Duration, and Age Limit */}
        <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-8">
          {/* Artist */}
          <div className="flex items-center space-x-2">
            <FaMusic className="text-white" />
            <span className="text-lg font-semibold">{artistName}</span>
          </div>

          {/* Duration */}
          <div className="flex items-center space-x-2">
            <FaClock className="text-white" />
            <span className="text-lg font-semibold">Duration: {duration} mins</span>
          </div>

          {/* Age Limit */}
          <div className="flex items-center space-x-2">
            <span className="text-sm bg-red-500 text-white px-4 py-1 rounded-full">{ageLimit}</span>
          </div>
        </div>

        {/* Show Details */}
        {shows && shows.length > 0 && (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Show Details</h2>
            {shows.map((show, index) => (
              <div
                key={index}
                className="p-6 mb-4 bg-gray-800 rounded-lg shadow-md"
              >
                <div className="flex items-center space-x-4 mb-2">
                  <FaClock className="text-white" />
                  <p className="text-lg">Start Time: {show.startTime}</p>
                </div>
                <div className="flex items-center space-x-4 mb-2">
                  <FaMapMarkerAlt className="text-white" />
                  <p className="text-lg">
                    {show.venue.name}, {show.venue.addressLine1}, {show.venue.city}, {show.venue.state} - {show.venue.pincode}
                  </p>
                </div>
                {/* <div className="text-sm text-gray-400">
                  <p>Booking Count: {show.bookingCount}</p>
                </div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MovieHero;
