
import AxiosInst from './instance';
const API = {};

const baseUrl = 'https://www.api.bookourseats.com';

//Registration of User API's
API.registerUserStep1 = data => {
  return AxiosInst.post(`${baseUrl}/auth/signup/create-account`, {
    name: data.name,
    phone: data.phone,
    email: data.email,
    password: data.password,
  });
};

API.registerUserStep2 = data => {
  return AxiosInst.post(`${baseUrl}/auth/generate-otp`, {
    email: data.email,
    password: data.password,
  });
};

API.registerUserStep3 = data => {
  return AxiosInst.post(`http://localhost:3000/auth/generate-otp`, data);
};

//Login of User API's
API.login = data => {
  return AxiosInst.post(`${baseUrl}/auth/login`, data);
};

//Forgot Password API's
API.requestOTP = data => {
  return AxiosInst.post(`${baseUrl}/auth/generate-otp`, data);
};
API.validateOTP = data => {
  console.log(data);
  return AxiosInst.post(`${baseUrl}/auth/validate-otp`, data);
};
API.resetPassword = data => {
  return AxiosInst.post(`${baseUrl}/auth/reset-password`, data);
};

//event data
API.events = () => {
  return AxiosInst.get(`${baseUrl}/event`);
};
//event data
API.getEventByID = ({id}) => {
  return AxiosInst.get(`${baseUrl}/event/${id}`);
};

API.seatLayout = ({showId}) => {
  return AxiosInst.get(`${baseUrl}/event/${showId}/layout`);
};

API.createBooking = data => {
  return AxiosInst.post(`${baseUrl}/booking`, data);
};

API.getBooking = (data) =>{
    return AxiosInst.get(`${baseUrl}/booking/${data}`)
}
API.getShowBooking = (data) => {
    return AxiosInst.get(`${baseUrl}/booking/`)
}

API.getUserDetails = () => {
  return AxiosInst.get(`${baseUrl}/user/info`);
};

API.updateUserDetails = userInfo => {
  return AxiosInst.post(`${baseUrl}/user/info`, {userInfo});
};

API.verifyBooking = (id) => {
  console.log(id, 'fghfgfghfg')
  return AxiosInst.post(`${baseUrl}/admin/verify-booking`, {bookingId : id})
}

export default API;