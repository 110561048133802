import React from 'react';
import { FaGift } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const MembershipIllustration = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-r from-darkBackground-700 to-purple-600 p-6 shadow-lg transform relative bottom-2  ">
      <h2 className="text-3xl font-extrabold text-white drop-shadow-md">Add Credits Up to ₹2000</h2>
      <div className="my-6 bg-white p-8 rounded-lg shadow-xl text-center transform transition-all duration-300 hover:shadow-2xl">
        <h3 className="text-2xl font-semibold text-darkBackground-700 flex items-center justify-center">
          <FaGift className="mr-2 text-3xl" />
          Get ₹2000 Credits Free!
        </h3>
        <p className="mt-2 text-gray-700 text-lg">Join Now and Enjoy More Benefits!</p>
      </div>
      <button onClick={() => navigate('/add_credits')} className="mt-6 bg-white text-darkBackground-700 font-bold py-3 px-6 rounded-full shadow-md transition duration-300 transform">
        Join Membership
      </button>
    </div>
  );
};

export default MembershipIllustration;
