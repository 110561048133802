import { useState, useEffect } from 'react';
import API from '../apis/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../components/Modals/Modal';
import TermsAndConditions from '../components/TermsNConditions/TermsAndConditions';
import { useToast } from '../components/ToastProvider';

const PreviewOrder = ({ token }) => {
    const { showToast } = useToast();
    const [modalVisible, setModalVisible] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [creditModalVisible, setCreditModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const cartParam = query.get('cart');
    const navigate = useNavigate()
    const cart = cartParam ? JSON.parse(decodeURIComponent(cartParam)) : null;

    let eventDetails = JSON.parse(localStorage.getItem('eventDetail'));

    const confirmBooking = () => {
        setModalVisible(false);
        setLoading(true);
        API.createBooking({
            showId: eventDetails.shows[0].id,
            eventId: eventDetails.id,
            showSeatIds: cart.selectedSeats.map(item => item.id),
            upiId: '1234567@ybl',
        })
            .then(({ data }) => {
                showToast('Successfull booking', 'success')
                setLoading(false);
                console.log(data)
                localStorage.setItem('bookingOrder', JSON.stringify(data))
                navigate(`/order_summary/${data.bookingId}`)
                // Redirect to order summary
            })
            .catch(err => {
                showToast(err.response.data.message, 'error');
                // navigate(`/order_summary/66af1e20-cfd8-42e0-be38-86ec7a84624f`)
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
            API.getUserDetails()
                .then(({ data }) => {
                    setLoading(false);
                    setUserProfile(data.userInfo);
                })
                .catch(err => {
                    setLoading(false);
                });
    }, []);

    const getSeatString = () => {
        return cart.selectedSeats.some(seat => seat.number === '-1')
            ? '-'
            : cart.selectedSeats.map(seat => seat.number).join(', ');
    };

    return (
        <div className="bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen flex flex-col items-center">
            <div className="overflow-auto w-full">
                <div className="relative">
                    <img
                        src={require('../assets/Banner1.jpeg')}
                        alt="Event Poster"
                        className="w-full h-[40vh] object-cover rounded-lg shadow-2xl transition duration-300 ease-in-out transform hover:scale-105"
                    />
                    <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-4 space-y-2">
                        <h2 className="text-white text-4xl font-bold drop-shadow-lg">{eventDetails?.title}</h2>
                    </div>
                </div>
                <div className="p-6 relative bottom-[80px] w-full max-w-2xl mx-auto">
                    <div className="bg-white rounded-xl shadow-2xl mb-6 p-8 transition duration-300 ease-in-out hover:shadow-3xl">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">{eventDetails?.title}</h2>
                        <p className="text-md text-gray-600 mb-6">{eventDetails?.description}</p>
                        <div className="flex justify-between mb-4">
                            <div>
                                <p className="text-sm text-gray-500">Artist</p>
                                <h3 className="text-lg text-gray-800">{eventDetails?.artistName}</h3>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Seats</p>
                                <h3 className="text-lg text-gray-800">{getSeatString()}</h3>
                            </div>
                        </div>
                        <div className="flex justify-between mb-4">
                            <div>
                                <p className="text-sm text-gray-500">Time</p>
                                <h3 className="text-lg text-gray-800">{eventDetails?.shows[0]?.startTime}</h3>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Date</p>
                                <h3 className="text-lg text-gray-800">{eventDetails?.shows[0]?.date}</h3>
                            </div>
                        </div>
                        <div className="mt-6 bg-darkBackground-700 p-3 rounded-lg">
                            <p className="text-white text-lg font-semibold">Total Amount: {cart?.totalamount}</p>
                        </div>
                        {userProfile && (
                            <p className="text-center text-red-600 mt-4 font-medium">Available Credits: {userProfile.coins}</p>
                        )}
                    </div>
                </div>
            </div>
            <TermsAndConditions eventDetails={eventDetails} />
            <div className="fixed bottom-0 w-full p-6 bg-white shadow-2xl">
                <button
                    className="w-full bg-darkBackground-700 text-white text-lg font-semibold rounded-xl py-3 transition duration-200 transform hover:bg-indigo-800 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-indigo-500 shadow-lg"
                    onClick={() => {
                        if (userProfile && cart.totalamount > userProfile.coins) {
                            setCreditModalVisible(true);
                        } else {
                            setModalVisible(true);
                        }
                    }}
                >
                    Book Now
                </button>
            </div>

            {/* Confirmation Modals */}
            {modalVisible && (
                <Modal onClose={() => setModalVisible(false)} onConfirm={confirmBooking} loading={loading} buttonName={"Confirm "}>
                    <p className="text-lg font-semibold">Are you sure you want to book the tickets?</p>
                </Modal>
            )}
            {creditModalVisible && (
                <Modal onClose={() => navigate('/')} buttonName={"Add Credits"} onConfirm={() => navigate('/add_credits')}>
                    <h3 className="text-lg font-semibold text-red-700">Insufficient Credits</h3>
                    <p>Please add credits to proceed with your booking.</p>
                </Modal>
            )}
        </div>
    );
};

export default PreviewOrder;
