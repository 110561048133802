import React, { useState, useEffect } from 'react';
import API from '../apis/api';
import Navbar from '../components/Navbar/Navbar.Component';
import BookingCard from '../components/BookingCard';

function BookingHistory({ token }) {
  const [bookings, setbookings] = useState([]);

  useEffect(() => {
    API.getShowBooking()
      .then((data) => {
        console.log(data.data.bookings);
        setbookings(data.data.bookings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <div className=''>
      <Navbar />
      <div className="flex flex-col min-h-screen items-center bg-white">
        {bookings?.length > 0 && bookings.map((data) => {
          console.log(data);
          return <BookingCard key={data.id} bookingDetails={data} />;
        })}
      </div>
    </div>
  );
}

export default BookingHistory;
