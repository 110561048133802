// ToastContext.js
import React, { createContext, useState, useContext } from 'react';

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ message: '', type: '' });

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast({ message: '', type: '' }), 3000); // Hide toast after 3 seconds
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.message && (
        <div
  style={{
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    color: 'whblacite',
    backgroundColor: toast.type === 'success' ? '#e6ffe6' : '#FFF4F3',
    zIndex: 9999, // Ensure it's above other elements
  }}
><span style={{ marginRight: '8px' }}>
    {toast.type === 'success' ? '✔️' : '❌'}
  </span>
  {toast.message}
</div>

)}
    </ToastContext.Provider>
  );
};
