import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import successAnimation from '../assets/SuccessAnimation.json';
import API from '../apis/api';
import { FaDollarSign, FaCalendarAlt, FaClock, FaMapMarkerAlt, FaTicketAlt } from 'react-icons/fa';

const OrderSummary = () => {
    const { bookingId } = useParams();
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        API.getBooking(bookingId)
            .then(({ data }) => {
                setOrderData(data.booking);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, [bookingId]);

    if (loading) return <div className="text-center text-xl">Loading...</div>;

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <div className="flex-grow overflow-auto mb-16 p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
                {orderData && (
                    <div className="flex flex-col items-center">
                        <div className="flex items-center justify-center p-4">
                            <h1 className="text-4xl text-darkBackground-700 font-bold p-1">Booking Successful!</h1>
                            <Lottie options={{ loop: false, autoplay: true, animationData: successAnimation }} height={150} width={150} />
                        </div>
                        <div className="rounded-2xl mx-6 p-4 bg-gradient-to-r from-blue-50 to-white shadow-lg">
                            <div className="flex flex-col items-center mb-4">
                                <img
                                    src={orderData.show.event.posterLink}
                                    alt={orderData.show.event.title}
                                    className="w-full h-48 rounded-lg object-cover shadow-md border border-gray-300"
                                />
                                <h2 className="text-3xl font-semibold text-darkBackground-700  mt-2">{orderData.show.event.title}</h2>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full py-4 ">
                                {[
                                    { icon: <span className='text-darkBackground-700 text-xl'>₹</span>, label: 'Total Amount', value: `Rs. ${orderData.paymentDetails.amount}` },
                                    { icon: <FaTicketAlt className='text-darkBackground-700' />, label: 'Seats', value: orderData.seats.join(', ') },
                                    { icon: <FaClock className='text-darkBackground-700' />, label: 'Time', value: orderData.show.startTime },
                                    { icon: <FaCalendarAlt className='text-darkBackground-700'/>, label: 'Date', value: orderData.show.date },
                                    { icon: <FaMapMarkerAlt className='text-darkBackground-700'/>, label: 'Place', value: orderData.show.venue.name },
                                ].map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center p-4 bg-white rounded-lg shadow-lg transition-transform "
                                    >
                                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 mr-4">
                                            <div className="text-2xl text-blue-500">{item.icon}</div>
                                        </div>
                                        <div className="flex-1">
                                            <p className="text-md text-gray-500">{item.label}</p>
                                            <p className="text-lg text-black font-bold">{item.value}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div className="flex flex-row w-fit m-5 bg-gray-100 rounded-lg p-4 shadow-md">
                                <div className="flex-1 pr-4 pt-2">
                                    <p className="text-md text-black">Use this QR code at the event entrance.</p>
                                    <p className="text-sm text-gray-400 pt-4">Booking ID</p>
                                    <p className="text-xs text-black font-semibold">{orderData.id}</p>
                                </div>
                                <div className="w-32 h-32 bg-white rounded-lg flex items-center justify-center shadow">
                                    {orderData.qrCode ? (
                                        <img src={orderData.qrCode} alt="QR Code" className="w-full h-full object-contain" />
                                    ) : (
                                        <p className="text-xs text-gray-400">No QR Code Available</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center mt-8">
                            <button
                                className="bg-darkBackground-700 text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:shadow-xl transition transform hover:scale-105"
                                onClick={() => navigate('/')}
                            >
                                Go to Home
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderSummary;
