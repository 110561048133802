// src/screens/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = ({ eventDetails }) => {
  return (
    <div className="flex flex-col bg-gray-100 p-4 relative bottom-16">
      <div className="flex-grow overflow-y-auto bg-white shadow-lg rounded-lg p-6 mb-4">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4 border-b pb-2">Terms and Conditions</h1>

        <div className="mb-6">
          <h2 className="text-lg font-bold text-gray-700">Age Limit:</h2>
          <p className="text-sm text-gray-600">{eventDetails.ageLimit ?? 'N/A'}</p>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-bold text-gray-700">Tickets Policy:</h2>
          <p className="text-sm text-gray-600">
            Tickets once booked cannot be exchanged or refunded. An Internet handling fee per ticket may be levied. Please check the total amount before payment. We recommend arriving at least 30 minutes prior to the venue.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-lg font-bold text-gray-700">COVID SAFETY GUIDELINES</h2>
          <p className="text-sm text-gray-600">
            Owing to the recent conditions surrounding the COVID-19 pandemic, as a pre-condition to gaining access to the venue (events and theatres) you are required to be fully vaccinated and may need to display your COVID-19 certificate as per the regulations prevailing in your State. The venue provider and governing authorities reserve the right to exclude any user from the venue for failure to abide by these protocols. You agree to exit without protest or refund. Users should check the restrictions applicable in their State.
            <br />
            Use of masks is mandatory at all times, and visitors are required to maintain social distancing norms. The venue and Bigtree reserve the right to change/modify the terms and conditions. Bigtree does not assume any responsibility regarding any injury or complications due to COVID-19 resulting from your participation. These guidelines are currently mandatory for Delhi/NCR, Maharashtra, and Karnataka and may vary depending on the state where the event is held.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
