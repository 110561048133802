import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setAuthorizationToken } from "../apis/instance";

const ProtectedRoute = ({ children }) => {
  const { accessToken } = useSelector((state) => state.auth);
  const token = localStorage.getItem("accessToken");
  console.log("Retrieved token:", token);
  setAuthorizationToken(token);
  console.log(accessToken, 'accessTokenaccessTokenaccessToken')
  // If user is not authenticated, redirect to login
  if (!accessToken && !token) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the protected component
  return children;
};

export default ProtectedRoute;
