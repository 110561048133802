import axios from 'axios';

const baseUrl = 'http://10.0.2.2:3000';

const AxiosInst = axios.create({
  baseURL: 'https://www.api.bookourseats.com',
  // baseURL: baseUrl,
});

export const setAuthorizationToken = token => {
  AxiosInst.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeAuthorizationToken = () => {
  delete AxiosInst.defaults.headers.Authorization;
};

export default AxiosInst;

