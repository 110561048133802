import React, { useRef } from 'react';
import { toPng } from 'html-to-image';

const IDCard = ({ profileData }) => {

    return (
        <div className="relative w-full max-w-md mx-auto bg-premier-600" style={{
            padding: 20,
            borderRadius: 8,
            marginTop: 20
        }}>
            <img
                src={require('../assets/title.png')}
                alt="ID Card Background"
                className="w-full h-auto"
            />
            <div className="flex flex-col items-start p-4">
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p style={{ color: "grey" }}>Name : </p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>
                        {profileData.name}
                    </p>
                </div>
                {/* <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p style={{ color: "grey" }}>Date of Birth: </p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>
                        {profileData.dob ?? "1September, 2024"}
                    </p>
                </div> */}
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p style={{ color: "grey" }}>Blood Group : </p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>
                        {profileData.blood_group ?? "O+"}
                    </p>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p style={{ color: "grey" }}>Mobile : </p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>
                        {profileData.phone ?? "O+"}
                    </p>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p style={{ color: "grey" }}>Address : </p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>
                        {profileData.address ?? "O+"}
                    </p>
                </div>
                <div style={{
                }}>
                    <p style={{ color: "grey" }}>Emergency Contact Person Name & Number :</p>
                    <p className="whitespace-nowrap ml-[2]" style={{ marginLeft: 5 }}>Vinod & 9819196385
                    </p>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <img
                        src={profileData.profileImage}
                        alt="Profile"
                        className="w-20 h-20 md:w-28 md:h-28  object-cover mt-4"
                        style={{
                            borderRadius: 8,
                            borderWidth: 1,
                            borderColor: "#6200EE",
                            padding: 2
                        }}
                    />

                    <img
                        src={require('../assets/QR_code.png')}
                        alt="Profile"
                        className="w-20 h-20 md:w-28 md:h-28  object-cover mt-4"
                        style={{
                            borderRadius: 8
                        }}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginLeft:"auto", marginRight:"auto" }}>
                    <img
                        src={require('../assets/signature.png')}
                        alt="Profile"
                        className="w-30 h-20 md:w-28 md:h-28  object-cover mt-4"
                        style={{
                            borderRadius: 8,
                            width:155
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default IDCard;
