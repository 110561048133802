import React, { useState, useEffect } from 'react';
import API from '../apis/api';
import DefaultLayoutHoc from '../layout/Default.layout';
import IDCard from '../components/IDCard';
import { useNavigate } from 'react-router-dom';

function ProfilePage({ token }) {
  const [isEditing, setIsEditing] = useState(false);
  const [showIDCard, setShowIDCard] = useState(false);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    profileImage: '',
    approvalStatus: false,
  });

  const [profileData, setProfileData] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);

  useEffect(() => {
    API.getUserDetails()
      .then(({ data }) => {
        setProfileData(data.userInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (profileData) {
      const isValidImage = profileData.profileImage && !profileData.profileImage.includes('placeholder.com');
      setFormData({
        name: profileData.name || '',
        email: profileData.email || '',
        phone: profileData.phone || '',
        address: profileData.address || '',
        city: profileData.city || '',
        state: profileData.state || '',
        pincode: profileData.pincode || '',
        profileImage: isValidImage ? profileData.profileImage : null,
        approvalStatus: profileData.approvalStatus || false,
      });
    }
  }, [profileData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prev) => ({
            ...prev,
            profileImage: reader.result,
          }));
          setImageChanged(true);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload an image in JPEG, JPG, or PNG format.');
      }
    }
  };

  const handleSave = async () => {
    try {
      const dataToSend = {
        ...formData,
        profileImage: imageChanged ? formData.profileImage : null,
      };
      await API.updateUserDetails(dataToSend);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleSubscriptionClick = () => {
   navigate('/membership')
  };

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="bg-white max-w-3xl w-full rounded-lg shadow-lg p-6 md:p-10">
        {profileData ? (
          <>
            <div className="flex flex-col items-center space-y-4">
              <div className="relative">
                <img
                  src={formData.profileImage || 'https://via.placeholder.com/150'}
                  alt="Profile"
                  className="w-24 h-24 md:w-32 md:h-32 rounded-full object-cover shadow-md transform transition-transform duration-300 hover:scale-105"
                />
                <input
                  type="file"
                  id="image-upload"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleImageChange}
                  className="hidden"
                />
                <label
                  htmlFor="image-upload"
                  className="cursor-pointer text-blue-600 mt-2"
                >
                  Change Image
                </label>
              </div>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="text-2xl md:text-3xl font-bold text-gray-900 border-b-2 border-gray-300 focus:border-gray-500 text-center"
                />
              ) : (
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900">{formData.name}</h1>
              )}
              <p className="text-gray-500">{formData.email}</p>
            </div>

            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">Phone</h2>
                  <p className="text-gray-800">{formData.phone}</p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">Address</h2>
                {isEditing ? (
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="text-gray-800 border-b-2 border-gray-300 focus:border-gray-500"
                  />
                ) : (
                  <p className="text-gray-800">{formData.address}</p>
                )}
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">City</h2>
                {isEditing ? (
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="text-gray-800 border-b-2 border-gray-300 focus:border-gray-500"
                  />
                ) : (
                  <p className="text-gray-800">{formData.city}</p>
                )}
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">State</h2>
                {isEditing ? (
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="text-gray-800 border-b-2 border-gray-300 focus:border-gray-500"
                  />
                ) : (
                  <p className="text-gray-800">{formData.state}</p>
                )}
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">Pincode</h2>
                {isEditing ? (
                  <input
                    type="text"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                    className="text-gray-800 border-b-2 border-gray-300 focus:border-gray-500"
                  />
                ) : (
                  <p className="text-gray-800">{formData.pincode}</p>
                )}
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">Coins</h2>
                <div className="flex items-center text-gray-800">
                  <span className="text-2xl text-yellow-500 mr-2">💰</span>
                  {profileData.coins}
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg shadow-sm transition hover:shadow-md">
                <h2 className="text-lg font-semibold text-gray-600">Approval Status</h2>
                <span
                  className={`inline-block mt-2 px-4 py-2 rounded-full text-white text-sm font-semibold ${
                    formData.approvalStatus ? 'bg-green-500' : 'bg-red-500'
                  }`}
                >
                  {formData.approvalStatus ? 'Approved' : 'Pending'}
                </span>
              </div>
            </div>

            <div className="mt-8 flex justify-center space-x-4">
              {isEditing ? (
                <>
                  <button
                    onClick={handleSave}
                    className="bg-darkBackground-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setIsEditing(false)}
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md shadow-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setIsEditing(true)}
                  className="bg-darkBackground-700 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
                >
                  Edit
                </button>
              )}
            </div>

            <div className="mt-8 flex justify-center">
              <button
                onClick={handleSubscriptionClick}
                className="bg-darkBackground-700 text-white px-4 py-2 rounded-md shadow-md"
              >
               View Subscription Card
              </button>
            </div>

            {showIDCard && <IDCard profileData={profileData} />}
          </>
        ) : (
          <div className="text-center text-gray-500">Loading profile data...</div>
        )}
      </div>
    </div>
  );
}

export default DefaultLayoutHoc(ProfilePage);
