import React, { useEffect, useState } from "react";
import axios from "axios";
// Components
import EntertainmentCardSlider from "../components/Entertainment/EntertainmentCard.Component";
import HeroCarousel from "../components/HeroCarousel/HeroCarousel.Component";
import PosterSlider from "../components/PosterSlider/PosterSlider.Component";
// Layout HOC
import DefaultLayoutHoc from "../layout/Default.layout";
import API from "../apis/api";
import { ToastProvider } from "../components/ToastProvider";
import { useSelector } from "react-redux";
import MembershipIllustration from "../components/MembershipIllustration";

const HomePage = ({ token }) => {
  const [recommendedMovies, setRecommendedMovies] = useState([]);
  const [premierMovies, setPremierMovies] = useState([]);
  const [onlineStreamEvents, setOnlineStreamEvents] = useState([]);
  const user = useSelector((state) => state.auth.user);
  console.log(user, 'reduxsu')

  useEffect(() => {
      const requestPopularMovies = async () => {
        API.events()
          .then(({ data }) => {
            console.log(data.events, 'requestPopularMoviesrequestPopularMovies')
            setRecommendedMovies(data.events);
            localStorage.setItem('allEvents', JSON.stringify(data.events))
          })
          .catch((err) => {
            console.log(err)
          })
        // setRecomm endedMovies(getPopularMovies.data.results);
      };
      requestPopularMovies();
  }, []);


  return (
    <ToastProvider>
      <div className="sticky top-0 z-20 bg-slate-800 hidden lg:block text-gray-200 w-full h-30">
      </div>
      <HeroCarousel />
      <MembershipIllustration />
      <div className="">
        <PosterSlider
          title="Upcoming Events"
          subtitle="List of upcoming movies"
          posters={recommendedMovies}
          isDark={false}
        />
      </div>
    </ToastProvider>
  );
};

export default DefaultLayoutHoc(HomePage);