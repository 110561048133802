// src/components/Modal.js
import React from 'react';

const Modal = ({ onClose, onConfirm, loading, children, buttonName }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">Confirm Action</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        &times;
                    </button>
                </div>
                <div className="mt-4">{children}</div>
                <div className="flex justify-end mt-6">
                    <button
                        onClick={onClose}
                        className="mr-4 px-4 py-2 text-gray-700 border rounded-md hover:bg-gray-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className={`px-4 py-2 text-white rounded-md ${loading ? 'bg-gray-400' : 'bg-darkBackground-700 hover:bg-premier-600'}`}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : buttonName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
