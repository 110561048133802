import React from 'react';
import Navbar from '../components/Navbar/Navbar.Component';

function AboutUs() {
  return (
    <div>
      <Navbar />
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">About Us</h1>
      <p className="text-lg text-gray-700 mb-4">
        Welcome to FTAM! We are excited to bring all our cultural activities together in one place.
        Our initiative aims to organize a yearly schedule where every two months, we plan either
        Cultural or Devotional programs. This platform will facilitate seating arrangements for our events.
      </p>

      <h2 className="text-2xl font-semibold text-blue-600 mt-6 mb-4">Activities We Offer</h2>
      <ul className="list-disc list-inside space-y-2 mb-6">
        <li>Cultural Organizing</li>
        <li>Health Care Initiatives</li>
        <li>Telugu Foods</li>
        <li>Membership Drive</li>
        <li>Telugu Badi</li>
        <li>Marathi Shala (Badi)</li>
        <li>Legal Team Support</li>
        <li>Startup / Business Team</li>
        <li>Job Help Services</li>
        <li>Various Classes</li>
        <ul className="list-disc list-inside ml-6 space-y-1">
          <li>Singing</li>
          <li>Music</li>
          <li>Dance</li>
          <li>Chess</li>
          <li>Violin</li>
          <li>Communication Skills for Kids</li>
        </ul>
        <li>Monthly Program Team</li>
      </ul>

      <p className="text-lg text-gray-700 mb-4">
        We will have 12 to 15 members in the Monthly Program Team, with each leading for one month.
        We also encourage trekking and welcome new ideas!
      </p>

      <p className="font-bold text-gray-800 text-center">Thanks, F-TAM</p>
    </div>
    </div>
  );
}

export default AboutUs;
