const Footer = () => {
    return (
      <footer className="bg-darkBackground-700 text-gray-300 py-8">
        <div className="container mx-auto text-center">
          <p className="text-sm">© 2024 Book our seats. All Rights Reserved.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="hover:text-white">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
          <div className="flex justify-center space-x-6 mt-4">
            <a href="/terms" className="text-sm hover:underline">Terms & Conditions</a>
            <a href="/privacy" className="text-sm hover:underline">Privacy Policy</a>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  