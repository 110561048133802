import React, { useState } from "react";
import HeroSlider from "react-slick";
import { NextArrow, PrevArrow } from "./Arrows.Component";
import Banner1 from '../../assets/Banner1.jpeg';
import Banner2 from '../../assets/Banner2.jpeg';
import Banner3 from '../../assets/Banner3.jpeg';
import Banner4 from '../../assets/Banner4.jpeg';

const HeroCarousel = () => {
  const [images, setImages] = useState([
    {
      title: "Fast and Furious 9",
      img: Banner1
    },
    {
      title: "Fast and Furious 9",
      img: Banner2
    },
    {
      title: "Fast and Furious 9",
      img: Banner3
    },
    {
      title: "Fast and Furious 9",
      img: Banner4
    },
  ]);

  const settingsLG = {
    arrows: true,
    slidesToShow: 1.7,
    infinite: true,
    speed: 2000,
    slideToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  const settings = {
    arrows: true,
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    slideToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className="lg:hidden">
        <HeroSlider {...settings}>
          {images.map((image, index) => (
            <div className="w-full h-56 md:h-80 " key={index}>
              <img
                src={image.img}
                alt={image.title}
                className="w-full h-full r object-cover"
              />
            </div>
          ))}
        </HeroSlider>
      </div>
      <div className="hidden lg:block">
        <HeroSlider {...settingsLG}>
          {images.map((image, index) => (
            <div className="w-full h-96  " key={index}>
              <img
                src={image.img}
                alt={image.title}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </HeroSlider>
      </div>
    </>
  );
};

export default HeroCarousel;
