import React from 'react';
import { useNavigate } from 'react-router-dom';

const StatusTag = ({ status }) => {
  let backgroundColor, textColor, statusText;

  switch (status.toLowerCase()) {
    case 'booked':
      backgroundColor = 'bg-premier-600';
      textColor = 'text-darkBackground-700';
      statusText = 'Booked';
      break;
    case 'cancelled':
      backgroundColor = 'bg-red-500';
      textColor = 'text-white';
      statusText = 'Cancelled';
      break;
    case 'pending':
      backgroundColor = 'bg-yellow-500';
      textColor = 'text-black';
      statusText = 'Pending';
      break;
    default:
      backgroundColor = 'bg-gray-500';
      textColor = 'text-white';
      statusText = 'Invalid';
  }

  return (
    <div className={`${backgroundColor} px-3 py-1 rounded-full`}>
      <span className={`${textColor} text-xs font-semibold`}>{statusText}</span>
    </div>
  );
};

const BookingCard = ({ bookingDetails }) => {
  const navigate = useNavigate();

  const {
    title,
    date,
    time,
    venue,
    tag,
    status,
    paymentDetails,
  } = bookingDetails;

  if (!paymentDetails) return null;

  const defaultUri =
    'https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80';

  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className="m-4 p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 border border-gray-200 w-full md:w-3/4 lg:w-2/4 m-16">
      <div className="flex justify-between items-center mb-4">
        <span className="text-sm text-gray-500">
          Booked on: <strong>{paymentDetails.timeStamp.split("T")[0] ?? "-"}</strong>
        </span>
        <StatusTag status={status} />
      </div>
      <div className="flex flex-col sm:flex-row sm:space-x-5">
        <img
          src={bookingDetails?.show?.event?.posterLink || defaultUri}
          alt={title}
          className="w-full sm:w-32 h-32 rounded-lg shadow-md object-cover"
        />
        <div className="flex-1 mt-4 sm:mt-0">
          <h3 className="text-xl sm:text-2xl font-semibold text-darkBackground-700">{bookingDetails?.show?.event?.title}</h3>
          <p className="text-black">{bookingDetails?.show?.startTime}</p>
          <p className="text-gray-600">{bookingDetails?.show?.venue?.name}</p>
          <p className="text-sm text-purple-600 mt-2">{tag}</p>
        </div>
      </div>
      <button
        onClick={() => navigate(`/booking-detail/${bookingDetails.id}`, { state: { bookingDetails } })}
        className="mt-5 w-full p-3 bg-darkBackground-700 text-white rounded-lg shadow hover:bg-darkBackground-700 transition duration-300"
      >
        View Details
      </button>
    </div>
  );
};

export default BookingCard;
