import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar.Component';
import Footer from '../components/Footer';

const AddCredits = () => {
    const handleEmailPress = () => {
        window.location.href = 'mailto:developer@bookourseats.com';
    };

    const handleContactPress = () => {
        window.location.href = 'tel:9920155702';
    };

    return (
        <div className="min-h-screen bg-gradient-to-r flex flex-col">
            <Navbar />
            <div className="flex flex-col items-center justify-center p-5 flex-1 w-full md:w-2/4 m-auto">
                <h1 className="text-4xl font-bold text-gray-800 md:text-5xl">Add Credits</h1>

                <div className="mt-5 rounded-lg overflow-hidden shadow-lg bg-white p-5">
                    <img
                        src={require('../assets/QR_code.png')}
                        alt="QR Code"
                        className="w-64 h-auto mx-auto"
                    />
                    <h2 className="text-lg text-white bg-gray-800 text-center p-2 mt-3 rounded">Scan to Pay</h2>
                </div>

                <p className="text-lg text-gray-600 text-center mt-5 mb-5 px-4">
                    Please send a screenshot of the payment receipt to{' '}
                    <span
                        className="font-bold text-blue-500 cursor-pointer hover:underline"
                        onClick={handleEmailPress}
                    >
                        contact@bookourseat.com
                    </span>. Your money will be credited to our account within 24-48 hours.
                </p>

                <button
                    className="bg-blue-500 text-white font-bold py-3 px-6 rounded-full mt-4 transition duration-300 hover:bg-blue-600"
                    onClick={handleContactPress}
                >
                    Contact Support
                </button>

                {/* Uncomment if you want to add a logo image */}
                {/* <img src={require('../../assets/icons/logo.png')} alt="Logo" className="w-48 h-auto mt-5" /> */}
            </div>
            <Footer />
        </div>
    );
};

export default AddCredits;
